import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { MatomoWidget } from 'components/common/matomo/MatomoWidget';
import { SgwtProvider } from 'components/common/SgwtProvider';
import { StoreProvider } from 'components/common/store';

import './css/bootstrapTheming.scss';
import './css/site.scss';
import { configureRum } from 'config/rum';

const startup = () => {
  configureRum();

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(
    <React.StrictMode>
      <StoreProvider>
        <SgwtProvider>
          <BrowserRouter>
            <MatomoWidget />
            <App />
          </BrowserRouter>
        </SgwtProvider>
      </StoreProvider>
    </React.StrictMode>,
  );
};

startup();