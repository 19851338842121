import { SgConnectEnvironment } from 'services/SgConnect';

export enum Environment {
    Test = 'Test',
    Local = 'Local',
    Development = 'Development',
    Homologation = 'Homologation',
    Preview = 'Preview',
    Production = 'Production',
}

const allEnvs: Record<Environment, string[]> = {
    [Environment.Test]: [],
    [Environment.Local]: [
        'local.fr.world.socgen',
        'localhost',
    ],
    [Environment.Development]: [
        'clientsubscriptionlist-dev.azureedge.net',
        'clientsubscriptionlist-dev.sgmarkets.com',
    ],
    [Environment.Homologation]: [
        'clientsubscriptionlist-uat.azureedge.net',
        'clientsubscriptionlist-uat.sgmarkets.com',
    ],
    [Environment.Preview]: [
        'clientsubscriptionlist-preview.azureedge.net',
        'clientsubscriptionlist-preview.sgmarkets.com',
    ],
    [Environment.Production]: [
        'clientsubscriptionlist.azureedge.net',
        'clientsubscriptionlist.sgmarkets.com',
    ],
};

export const getEnvironment = (): Environment => {
    if ((window as any)?.__vitest_environment__) {
        return Environment.Test;
    }

    const env = Object.values(Environment)
        .find(x => allEnvs[x].includes(window.location.hostname));

    if (env) {
        return env;
    }

    throw new Error(`Can't find environment from hostname ${window.location.hostname}`);
};

interface IConfiguration {
    baseApiUrls: {
        clientSubscriptionList: string;
    };
    externalLinks: {
        sgMarketsUrl: string;
    },
    authorizationEndpoint: string;
    clientId: string;
    scope: string;
    requestAccessUrl: string;
    sgConnectEnvironment: SgConnectEnvironment;
    sharedWidgetBaseUrl: string;
    matomoSiteId: string;
    matomoUrl?: string;
    recipientPageSize: number;
    historyPageSize: number;
    listPageSize: number;
    debounceWaitTimeInMs: number;
    sgMonitoringRumUrl: string;
}

const defaultConfiguration: IConfiguration = {
    baseApiUrls: {
        clientSubscriptionList: 'https://sgmarkets-api-list-management-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
    },
    externalLinks: {
        sgMarketsUrl: 'https://www-dev.sgmarkets.com',
    },
    clientId: '206ee1ab-7eb7-4942-b9c7-3223af576f3e',
    authorizationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
    scope: [
        'mail',
        'openid',
        'profile',
        'api.sg-markets-communication-hub-list-management.v1',
        'api.manage-contacts.v1',
    ].join(' '),
    sgConnectEnvironment: SgConnectEnvironment.Homologation,
    requestAccessUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://pretrade-api-content-dev.fr.world.socgen',
    sharedWidgetBaseUrl: 'https://shared-uat.sgmarkets.com',
    matomoSiteId: '61261518',
    matomoUrl: 'https://sg-analytics-uat.fr.world.socgen/piwik/',
    recipientPageSize: 20,
    historyPageSize: 20,
    listPageSize: 10,
    debounceWaitTimeInMs: 500,
    sgMonitoringRumUrl: 'https://insight-realm-apm-hom.fleet.uat.sgmonitoring.dev.euw.gbis.sg-azure.com',
};

const getConfiguration = (): IConfiguration => {
    const env = getEnvironment();

    switch (env) {
        case Environment.Test:
            return {
                ...defaultConfiguration,
                baseApiUrls: {
                    clientSubscriptionList: 'https://localhost',
                },
            };
        case Environment.Local:
        case Environment.Development:
            return {
                ...defaultConfiguration,
                baseApiUrls: {
                    clientSubscriptionList: 'https://sgmarkets-api-list-management-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
                },
            };
        case Environment.Homologation:
            return {
                ...defaultConfiguration,
                baseApiUrls: {
                    clientSubscriptionList: 'https://sgmarkets-api-list-management-uat.sgmpinsight.dev.euw.gbis.sg-azure.com',
                },
                externalLinks: {
                    ...defaultConfiguration.externalLinks,
                    sgMarketsUrl: 'https://www-uat.sgmarkets.com',
                },
            };
        case Environment.Preview:
        case Environment.Production:
            return {
                ...defaultConfiguration,
                baseApiUrls: {
                    clientSubscriptionList: 'https://sgmarkets-api-list-management-prd.sgmpinsight.prd.euw.gbis.sg-azure.com',
                },
                externalLinks: {
                    sgMarketsUrl: 'https://sgmarkets.com',
                },
                clientId: '3222bd49-9cc6-4900-9ec2-dcf7b97630e4',
                authorizationEndpoint: 'https://sso.sgmarkets.com/sgconnect',
                sgConnectEnvironment: SgConnectEnvironment.Production,
                sharedWidgetBaseUrl: 'https://shared.sgmarkets.com/',
                matomoSiteId: '1208',
                matomoUrl: 'https://t-log.sgmarkets.com/',
                sgMonitoringRumUrl: 'https://t-monitoring-rum.sgmarkets.com/rum/540a239031b64d7fbe34fe54fef041db',
            };
    }
};

export const configuration = getConfiguration();
